var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"close-on-click":_vm.closeOnClick,"close-on-content-click":_vm.closeOnContentClick,"left":!_vm.AppRtl,"right":_vm.AppRtl,"bottom":"","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":_vm.badgeColor,"left":"","overlap":"","loading":_vm.fetching},scopedSlots:_vm._u([(_vm.unreadCount)?{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.unreadCount))])]},proxy:true}:null],null,true)},[_vm._v(" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-bell")])],1)],1)]}}]),model:{value:(_vm.menuShow),callback:function ($$v) {_vm.menuShow=$$v},expression:"menuShow"}},[_vm._v(" "),(_vm.fetching)?[_c('app-skeleton',{attrs:{"loading":_vm.fetching}})]:[_c('v-list',{attrs:{"min-width":_vm.minWidth,"width":_vm.width,"nav":"","two-line":""}},[(_vm.hasNotifications)?[_vm._l((_vm.notificationsComputed),function(notification){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{ 'primary whiteOnHover elevation-12': hover, 'secondary':!hover&&notification.unread},staticStyle:{"min-height":"40px"},attrs:{"link":""},on:{"click":function($event){return _vm.clickNotification(notification)}}},[_c('v-list-item-avatar',{staticClass:"pa-0 ma-0",staticStyle:{"color":"inherit !important"}},[_c('v-icon',{staticStyle:{"color":"inherit !important"}},[_vm._v(_vm._s(notification.icon))])],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"pa-0 ma-0",staticStyle:{"color":"inherit"},attrs:{"dense":""}},[_c('v-list-item-subtitle',{staticClass:"text-center",staticStyle:{"color":"inherit","white-space":"initial"},attrs:{"dense":""}},[_vm._v(_vm._s(notification.text))])],1)],1)]}}],null,true)})]})]:[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('none')))])],_vm._v(" "),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{ 'primary whiteOnHover elevation-12': hover},staticStyle:{"min-height":"40px"},attrs:{"link":""},on:{"click":function($event){return _vm.clickAllNotification()}}},[_c('v-list-item-avatar',{staticClass:"pa-0 ma-0",staticStyle:{"color":"inherit !important"}},[_c('v-icon',{staticStyle:{"color":"inherit !important"}},[_vm._v("apps")])],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"pa-0 ma-0",staticStyle:{"color":"inherit"},attrs:{"dense":""}},[_c('v-list-item-subtitle',{staticClass:"text-center",staticStyle:{"color":"inherit","white-space":"initial"},attrs:{"dense":""}},[_vm._v(_vm._s(_vm.$t('all_notifications')))])],1)],1)]}}])})],2)]],2),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"1024"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',{attrs:{"flat":"","loader-height":"30","tile":""}},[_c('v-toolbar',{staticClass:"justify-content-center",attrs:{"color":"secondary","max-height":"30","height":"30"}},[_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.dialog.title)}})],1),_vm._v(" "),_c('v-card-text',{staticClass:"pt-2"},[_c('div',{staticClass:"pt-2"},[_vm._l((_vm.allnotifications),function(notification){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('app-row',{class:{ 'primary whiteOnHover elevation-12 pa-0 ma-0': hover,'pa-0 ma-0':!hover},staticStyle:{"min-height":"40px"},attrs:{"link":""},on:{"click":function($event){return _vm.clickNotification(notification)}}},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12","dense":""}},[_c('v-card-text',{staticClass:"pa-0 pt-2 ma-0 text-center",staticStyle:{"color":"inherit"},attrs:{"dense":""}},[_vm._v("\n                        "+_vm._s(notification.text)+"\n                      ")])],1)],1)]}}],null,true)})]})],2)]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('app-btn',{attrs:{"color":"error","min-width":"120","tooltip":"close_window"},on:{"click":_vm.closeDialog}},[_vm._v("\n            "+_vm._s(_vm.$t('close'))+"\n          ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }