import routes, {HOME_ROUTE_NAME} from '@routes/config';
var routeAlliesbypermsc = [];
var RoutesbyPermsc = JSON.parse(localStorage.getItem('backend_routes'));
if(RoutesbyPermsc) {
    if (RoutesbyPermsc['main-screen-home']) {
        routeAlliesbypermsc.push(...[{
            path: routes.mainScreen.cliamsdetails, name: routes.mainScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "MainScreen" */ '@components/MainScreen')
        }]);
    }
    if (RoutesbyPermsc['car-main-screen-home']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carMainScreen.cliamsdetails, name: routes.carMainScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "CarMainScreen" */ '@components/CarMainScreen')
        }]);
    }
    if (RoutesbyPermsc['policy-main-screen-home']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyMainScreen.cliamsdetails, name: routes.policyMainScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "PolicyMainScreen" */ '@components/PolicyMainScreen')
        }]);
    }
    if (RoutesbyPermsc['medical-main-screen-home']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalMainScreen.cliamsdetails, name: routes.medicalMainScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "MedicalMainScreen" */ '@components/MedicalMainScreen')
        }]);
    }
    if (RoutesbyPermsc['car-admin-main-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carAdminMainScreen.cliamsdetails, name: routes.carAdminMainScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "CarAdminMainScreen" */ '@components/CarAdminMainScreen')
        }]);
    }
    if (RoutesbyPermsc['policy-admin-main-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyAdminMainScreen.cliamsdetails, name: routes.policyAdminMainScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "PolicyAdminMainScreen" */ '@components/PolicyAdminMainScreen')
        }]);
    }
    if (RoutesbyPermsc['car-reassign-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carReassignScreen.cliamsdetails, name: routes.carReassignScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "CarReassignScreen" */ '@components/CarReassignScreen')
        }]);
    }
    if (RoutesbyPermsc['policy-reassign-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyReassignScreen.cliamsdetails, name: routes.policyReassignScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "PolicyReassignScreen" */ '@components/PolicyReassignScreen')
        }]);
    }
    if (RoutesbyPermsc['car-noassign-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carNoassignScreen.cliamsdetails, name: routes.carNoassignScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "CarNoassignScreen" */ '@components/CarNoassignScreen')
        }]);
    }
    if (RoutesbyPermsc['policy-noassign-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyNoassignScreen.cliamsdetails, name: routes.policyNoassignScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "PolicyNoassignScreen" */ '@components/PolicyNoassignScreen')
        }]);
    }
    if (RoutesbyPermsc['car-arrange-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carArrangeScreen.cliamsdetails, name: routes.carArrangeScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "CarArrangeScreen" */ '@components/CarArrangeScreen')
        }]);
    }
    if (RoutesbyPermsc['car-finance-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carFinanceScreen.cliamsdetails, name: routes.carFinanceScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "CarFinanceScreen" */ '@components/CarFinanceScreen')
        }]);
    }
    if (RoutesbyPermsc['policy-arrange-screen']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyArrangeScreen.cliamsdetails, name: routes.policyArrangeScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "PolicyArrangeScreen" */ '@components/PolicyArrangeScreen')
        }]);
    }
    if (RoutesbyPermsc['car-entry-screen-home']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carEntryScreen.cliamsdetails, name: routes.carEntryScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "CarEntryScreen" */ '@components/CarEntryScreen')
        }]);
    }
    if (RoutesbyPermsc['legal-main-screen-home']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalMainScreen.cliamsdetails, name: routes.legalMainScreen.cliamsdetails,
            component: () => import(/* webpackChunkName: "LegalMainScreen" */ '@components/LegalMainScreen')
        }]);
    }
    if (RoutesbyPermsc['insurance-company-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.insuranceCompany.index, name: routes.insuranceCompany.index,
            component: () => import(/* webpackChunkName: "InsuranceCompany" */ '@components/InsuranceCompany/Index')
        }]);
    }
    if (RoutesbyPermsc['consumer-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.consumer.index, name: routes.consumer.index,
            component: () => import(/* webpackChunkName: "Consumer" */ '@components/Consumer/Index')
        }]);
    }
    if (RoutesbyPermsc['accident-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.accidentType.index, name: routes.accidentType.index,
            component: () => import(/* webpackChunkName: "AccidentType" */ '@components/AccidentType/Index')
        }]);
    }
    if (RoutesbyPermsc['city-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.city.index, name: routes.city.index,
            component: () => import(/* webpackChunkName: "City" */ '@components/City/Index')
        }]);
    }
    if (RoutesbyPermsc['claim-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.claimType.index, name: routes.claimType.index,
            component: () => import(/* webpackChunkName: "ClaimType" */ '@components/ClaimType/Index')
        }]);
    }
    if (RoutesbyPermsc['vehicle-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.vehicleType.index, name: routes.vehicleType.index,
            component: () => import(/* webpackChunkName: "VehicleType" */ '@components/VehicleType/Index')
        }]);
    }
    if (RoutesbyPermsc['division-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.division.index, name: routes.division.index,
            component: () => import(/* webpackChunkName: "Division" */ '@components/Division/Index')
        }]);
    }
    if (RoutesbyPermsc['hospital-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.hospital.index, name: routes.hospital.index,
            component: () => import(/* webpackChunkName: "Hospital" */ '@components/Hospital/Index')
        }]);
    }
    if (RoutesbyPermsc['claim-status-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.claimStatus.index, name: routes.claimStatus.index,
            component: () => import(/* webpackChunkName: "ClaimStatus" */ '@components/ClaimStatus/Index')
        }]);
    }
    if (RoutesbyPermsc['claim-status-field-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.claimStatusField.index, name: routes.claimStatusField.index,
            component: () => import(/* webpackChunkName: "ClaimStatusField" */ '@components/ClaimStatusField/Index')
        }]);
    }
    if (RoutesbyPermsc['accident-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.accident.index, name: routes.accident.index,
            component: () => import(/* webpackChunkName: "Accident" */ '@components/Accident/Index')
        }]);
    }
    if (RoutesbyPermsc['claim-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.claim.index, name: routes.claim.index,
            component: () => import(/* webpackChunkName: "Claim" */ '@components/Claim/Index')
        }]);
    }
    if (RoutesbyPermsc['medical-claim-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalClaim.index, name: routes.medicalClaim.index,
            component: () => import(/* webpackChunkName: "MedicalClaim" */ '@components/MedicalClaim/Index')
        }]);
    }
    if (RoutesbyPermsc['party-person-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.partyPersonType.index, name: routes.partyPersonType.index,
            component: () => import(/* webpackChunkName: "PartyPersonType" */ '@components/PartyPersonType/Index')
        }]);
    }
    if (RoutesbyPermsc['plate-digit-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.plateDigit.index, name: routes.plateDigit.index,
            component: () => import(/* webpackChunkName: "PlateDigit" */ '@components/PlateDigit/Index')
        }]);
    }
    if (RoutesbyPermsc['client-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.client.index, name: routes.client.index,
            component: () => import(/* webpackChunkName: "Client" */ '@components/Client/Index')
        }]);
    }
    if (RoutesbyPermsc['year-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.year.index, name: routes.year.index,
            component: () => import(/* webpackChunkName: "Year" */ '@components/Year/Index')
        }]);
    }
    if (RoutesbyPermsc['party-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.partydata.index, name: routes.partydata.index,
            component: () => import(/* webpackChunkName: "PartyData" */ '@components/PartyData/Index')
        }]);
    }
    if (RoutesbyPermsc['party-person-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.partyPerson.index, name: routes.partyPerson.index,
            component: () => import(/* webpackChunkName: "PartyPerson" */ '@components/PartyPerson/Index')
        }]);
    }
    if (RoutesbyPermsc['hospital-contact-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.hospitalContact.index, name: routes.hospitalContact.index,
            component: () => import(/* webpackChunkName: "HospitalContact" */ '@components/HospitalContact/Index')
        }]);
    }
    if (RoutesbyPermsc['consumer-work-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.consumerWork.index, name: routes.consumerWork.index,
            component: () => import(/* webpackChunkName: "ConsumerWork" */ '@components/ConsumerWork/Index')
        }]);
    }
    if (RoutesbyPermsc['vehicle-model-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.vehicleModel.index, name: routes.vehicleModel.index,
            component: () => import(/* webpackChunkName: "VehicleModel" */ '@components/VehicleModel/Index')
        }]);
    }
    if (RoutesbyPermsc['party-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.partyType.index, name: routes.partyType.index,
            component: () => import(/* webpackChunkName: "PartyType" */ '@components/PartyType/Index')
        }]);
    }
    if (RoutesbyPermsc['menu-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.menu.index, name: routes.menu.index,
            component: () => import(/* webpackChunkName: "Menu" */ '@components/Menu/Index')
        }]);
    }
    if (RoutesbyPermsc['role-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.role.index, name: routes.role.index,
            component: () => import(/* webpackChunkName: "Role" */ '@components/Role/Index')
        }]);
    }
    if (RoutesbyPermsc['permission-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.permission.index, name: routes.permission.index,
            component: () => import(/* webpackChunkName: "Permission" */ '@components/Permission/Index')
        }]);
    }
    if (RoutesbyPermsc['document-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.documentType.index,name: routes.documentType.index,
            component: () => import(/* webpackChunkName: "DocumentType" */  '@components/DocumentType/Index')
        }]);
    }
    if (RoutesbyPermsc['medical-reportone']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReportone.index,name: routes.medicalReportone.index,
            component: () => import(/* webpackChunkName: "medicalReport1" */  '@components/MedicalReports/report1')
        }]);
    }
    if (RoutesbyPermsc['medical-reporttwo']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReporttwo.index,name: routes.medicalReporttwo.index,
            component: () => import(/* webpackChunkName: "medicalReport2" */  '@components/MedicalReports/report2')
        }]);
    }
    if (RoutesbyPermsc['medical-reportthree']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReportthree.index,name: routes.medicalReportthree.index,
            component: () => import(/* webpackChunkName: "medicalReport3" */  '@components/MedicalReports/report3')
        }]);
    }
    if (RoutesbyPermsc['medical-reportfour']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReportfour.index,name: routes.medicalReportfour.index,
            component: () => import(/* webpackChunkName: "medicalReport4" */  '@components/MedicalReports/report4')
        }]);
    }
    if (RoutesbyPermsc['medical-reportfive']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReportfive.index,name: routes.medicalReportfive.index,
            component: () => import(/* webpackChunkName: "medicalReport5" */  '@components/MedicalReports/report5')
        }]);
    }
    if (RoutesbyPermsc['medical-reportsix']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReportsix.index,name: routes.medicalReportsix.index,
            component: () => import(/* webpackChunkName: "medicalReport6" */  '@components/MedicalReports/report6')
        }]);
    }
    if (RoutesbyPermsc['medical-reportseven']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReportseven.index,name: routes.medicalReportseven.index,
            component: () => import(/* webpackChunkName: "medicalReport7" */  '@components/MedicalReports/report7')
        }]);
    }
    if (RoutesbyPermsc['medical-reporteight']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalReporteight.index,name: routes.medicalReporteight.index,
            component: () => import(/* webpackChunkName: "medicalReport8" */  '@components/MedicalReports/report8')
        }]);
    }
    if (RoutesbyPermsc['vehicle-reportone']) {
        routeAlliesbypermsc.push(...[{
            path: routes.vehicleReportone.index,name: routes.vehicleReportone.index,
            component: () => import(/* webpackChunkName: "vehicleReport1" */  '@components/VehicleReports/report1')
        }]);
    }
    if (RoutesbyPermsc['vehicle-reporttwo']) {
        routeAlliesbypermsc.push(...[{
            path: routes.vehicleReporttwo.index,name: routes.vehicleReporttwo.index,
            component: () => import(/* webpackChunkName: "vehicleReport2" */  '@components/VehicleReports/report2')
        }]);
    }
    if (RoutesbyPermsc['vehicle-reportthree']) {
        routeAlliesbypermsc.push(...[{
            path: routes.vehicleReportthree.index,name: routes.vehicleReportthree.index,
            component: () => import(/* webpackChunkName: "vehicleReport3" */  '@components/VehicleReports/report3')
        }]);
    }
    if (RoutesbyPermsc['vehicle-reportfour']) {
        routeAlliesbypermsc.push(...[{
            path: routes.vehicleReportfour.index,name: routes.vehicleReportfour.index,
            component: () => import(/* webpackChunkName: "vehicleReport4" */  '@components/VehicleReports/report4')
        }]);
    }
    if (RoutesbyPermsc['vehicle-reportfive']) {
        routeAlliesbypermsc.push(...[{
            path: routes.vehicleReportfive.index,name: routes.vehicleReportfive.index,
            component: () => import(/* webpackChunkName: "vehicleReport5" */  '@components/VehicleReports/report5')
        }]);
    }
    if (RoutesbyPermsc['policy-reportone']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyReportone.index,name: routes.policyReportone.index,
            component: () => import(/* webpackChunkName: "policyReport1" */  '@components/PolicyReports/report1')
        }]);
    }
    if (RoutesbyPermsc['policy-reporttwo']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyReporttwo.index,name: routes.policyReporttwo.index,
            component: () => import(/* webpackChunkName: "policyReport2" */  '@components/PolicyReports/report2')
        }]);
    }
    if (RoutesbyPermsc['policy-reportthree']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyReportthree.index,name: routes.policyReportthree.index,
            component: () => import(/* webpackChunkName: "policyReport3" */  '@components/PolicyReports/report3')
        }]);
    }
    if (RoutesbyPermsc['policy-reportfour']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyReportfour.index,name: routes.policyReportfour.index,
            component: () => import(/* webpackChunkName: "policyReport4" */  '@components/PolicyReports/report4')
        }]);
    }
    if (RoutesbyPermsc['legal-case-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalCase.index, name: routes.legalCase.index, component: () => import(/* webpackChunkName: "LegalCase" */  '@components/LegalCase/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-case-status-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalCaseStatus.index, name: routes.legalCaseStatus.index,
            component: () => import(/* webpackChunkName: "LegalCaseStatus" */  '@components/LegalCaseStatus/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-client-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalClient.index, name: routes.legalClient.index,
            component: () => import(/* webpackChunkName: "LegalClient" */  '@components/LegalClient/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-litigation-degree-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalLitigationDegree.index, name: routes.legalLitigationDegree.index, component: () => import(/* webpackChunkName: "LegalLitigationDegree" */  '@components/LegalLitigationDegree/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-party-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalParty.index, name: routes.legalParty.index, component: () => import(/* webpackChunkName: "LegalParty" */  '@components/LegalParty/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-party-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalPartyType.index, name: routes.legalPartyType.index, component: () => import(/* webpackChunkName: "LegalPartyType" */  '@components/LegalPartyType/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-client-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalClientType.index, name: routes.legalClientType.index, component: () => import(/* webpackChunkName: "LegalClientType" */  '@components/LegalClientType/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-case-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalCaseType.index, name: routes.legalCaseType.index, component: () => import(/* webpackChunkName: "LegalCaseType" */  '@components/LegalCaseType/Index')
        }]);
    }
    if (RoutesbyPermsc['car-client-index']) {routeAlliesbypermsc.push(...[{path: routes.carClient.index, name: routes.carClient.index,
        component: () => import(/* webpackChunkName: "CarClient" */  '@components/CarClient/Index')}]);}
    if (RoutesbyPermsc['car-claim-type-index']) {routeAlliesbypermsc.push(...[{path: routes.carClaimType.index, name: routes.carClaimType.index,
        component: () => import(/* webpackChunkName: "CarClaimType" */  '@components/CarClaimType/Index')}]);}
    if (RoutesbyPermsc['car-claim-index']) {routeAlliesbypermsc.push(...[{path: routes.carClaim.index, name: routes.carClaim.index,
        component: () => import(/* webpackChunkName: "CarClaim" */  '@components/CarClaim/Index')}]);}
    if (RoutesbyPermsc['car-division-index']) {routeAlliesbypermsc.push(...[{path: routes.carDivision.index, name: routes.carDivision.index,
        component: () => import(/* webpackChunkName: "CarDivision" */  '@components/CarDivision/Index')}]);}
    if (RoutesbyPermsc['car-insurance-company-index']) {routeAlliesbypermsc.push(...[{path: routes.carInsuranceCompany.index, name: routes.carInsuranceCompany.index,
        component: () => import(/* webpackChunkName: "CarInsuranceCompany" */  '@components/CarInsuranceCompany/Index')}]);}
    if (RoutesbyPermsc['car-vehicle-type-index']) {routeAlliesbypermsc.push(...[{path: routes.carVehicleType.index, name: routes.carVehicleType.index,
        component: () => import(/* webpackChunkName: "CarVehicleType" */  '@components/CarVehicleType/Index')}]);}
    if (RoutesbyPermsc['car-vehicle-kind-index']) {routeAlliesbypermsc.push(...[{path: routes.carVehicleKind.index, name: routes.carVehicleKind.index,
        component: () => import(/* webpackChunkName: "CarVehicleKind" */  '@components/CarVehicleKind/Index')}]);}
    if (RoutesbyPermsc['car-vehicle-model-index']) {routeAlliesbypermsc.push(...[{path: routes.carVehicleModel.index, name: routes.carVehicleModel.index,
        component: () => import(/* webpackChunkName: "CarVehicleModel" */  '@components/CarVehicleModel/Index')}]);}
    if (RoutesbyPermsc['car-accident-destination-index']) {routeAlliesbypermsc.push(...[{path: routes.caraccidentdestination.index, name: routes.caraccidentdestination.index,
        component: () => import(/* webpackChunkName: "CarAccidentDestination" */  '@components/CarAccidentDestination/Index')}]);}
    if (RoutesbyPermsc['car-accident-destination-type-index']) {routeAlliesbypermsc.push(...[{path: routes.caraccidentdestinationType.index, name: routes.caraccidentdestinationType.index,
        component: () => import(/* webpackChunkName: "CarAccidentDestinationType" */  '@components/CarAccidentDestinationType/Index')}]);}
    if (RoutesbyPermsc['car-entry-action-index']) {routeAlliesbypermsc.push(...[{path: routes.carentryAction.index, name: routes.carentryAction.index,
        component: () => import(/* webpackChunkName: "CarEntryAction" */  '@components/CarEntryAction/Index')}]);}
    if (RoutesbyPermsc['car-entry-action-type-index']) {routeAlliesbypermsc.push(...[{path: routes.carentryActionType.index, name: routes.carentryActionType.index,
        component: () => import(/* webpackChunkName: "CarEntryActionType" */  '@components/CarEntryActionType/Index')}]);}
    if (RoutesbyPermsc['car-refund-reason-index']) {routeAlliesbypermsc.push(...[{path: routes.carRefundReason.index, name: routes.carRefundReason.index,
        component: () => import(/* webpackChunkName: "CarRefundReason" */  '@components/CarRefundReason/Index')}]);}
    if (RoutesbyPermsc['car-claim-status-index']) {routeAlliesbypermsc.push(...[{path: routes.carClaimStatus.index, name: routes.carClaimStatus.index,
        component: () => import(/* webpackChunkName: "CarClaimStatus" */  '@components/CarClaimStatus/Index')}]);}
    if (RoutesbyPermsc['medical-claim-status-index']) {routeAlliesbypermsc.push(...[{path: routes.medicalClaimStatus.index, name: routes.medicalClaimStatus.index,
        component: () => import(/* webpackChunkName: "MedicalClaimStatus" */  '@components/MedicalClaimStatus/Index')}]);}
    if (RoutesbyPermsc['car-claim-status-field-index']) {routeAlliesbypermsc.push(...[{path: routes.carClaimStatusField.index, name: routes.carClaimStatusField.index,
        component: () => import(/* webpackChunkName: "CarClaimStatusField" */  '@components/CarClaimStatusField/Index')}]);}
    if (RoutesbyPermsc['medical-claim-status-field-index']) {routeAlliesbypermsc.push(...[{path: routes.medicalClaimStatusField.index, name: routes.medicalClaimStatusField.index,
        component: () => import(/* webpackChunkName: "MedicalClaimStatusField" */  '@components/MedicalClaimStatusField/Index')}]);
    }
    if (RoutesbyPermsc['car-city-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carCity.index, name: routes.carCity.index,
            component: () => import(/* webpackChunkName: "CarCity" */ '@components/CarCity/Index')
        }]);
    }
    if (RoutesbyPermsc['car-sms-template-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carSmsTemplate.index, name: routes.carSmsTemplate.index,
            component: () => import(/* webpackChunkName: "CarSmsTemplate" */ '@components/CarSmsTemplate/Index')
        }]);
    }
    if (RoutesbyPermsc['car-close-collected-claim-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carCloseCollectedClaim.index, name: routes.carCloseCollectedClaim.index,
            component: () => import(/* webpackChunkName: "CarCloseCollectedClaim" */ '@components/CarCloseCollectedClaim/Index')
        }]);
    }
    if (RoutesbyPermsc['car-close-collected-row-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.carCloseCollectedRow.index, name: routes.carCloseCollectedRow.index,
            component: () => import(/* webpackChunkName: "CarCloseCollectedRow" */ '@components/CarCloseCollectedRow/Index')
        }]);
    }
    if (RoutesbyPermsc['close-collected-claim-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.closeCollectedClaim.index, name: routes.closeCollectedClaim.index,
            component: () => import(/* webpackChunkName: "CloseCollectedClaim" */ '@components/CloseCollectedClaim/Index')
        }]);
    }
    if (RoutesbyPermsc['close-collected-row-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.closeCollectedRow.index, name: routes.closeCollectedRow.index,
            component: () => import(/* webpackChunkName: "CloseCollectedRow" */ '@components/CloseCollectedRow/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-claim-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyClaim.index, name: routes.policyClaim.index,
            component: () => import(/* webpackChunkName: "PolicyClaim" */  '@components/PolicyClaim/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-client-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyClient.index, name: routes.policyClient.index,
            component: () => import(/* webpackChunkName: "PolicyClient" */  '@components/PolicyClient/Index')
        }]);
    }
    if (RoutesbyPermsc['business-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.businessType.index, name: routes.businessType.index,
            component: () => import(/* webpackChunkName: "BusinessType" */  '@components/BusinessType/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-assigned-claim-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyAssignedClaim.index, name: routes.policyAssignedClaim.index,
            component: () => import(/* webpackChunkName: "PolicyAssignedClaim" */  '@components/PolicyAssignedClaim/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-claim-note-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyClaimNote.index, name: routes.policyClaimNote.index,
            component: () => import(/* webpackChunkName: "PolicyClaimNote" */  '@components/PolicyClaimNote/Index')
        }]);
    }
    if (RoutesbyPermsc['party-status-step-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.partyStatusStep.index, name: routes.partyStatusStep.index,
            component: () => import(/* webpackChunkName: "PartyStatusStep" */  '@components/PartyStatusStep/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-claim-status-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyClaimStatus.index, name: routes.policyClaimStatus.index,
            component: () => import(/* webpackChunkName: "PolicyClaimStatus" */  '@components/PolicyClaimStatus/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-claim-status-field-row-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyClaimStatusFieldRow.index, name: routes.policyClaimStatusFieldRow.index,
            component: () => import(/* webpackChunkName: "PolicyClaimStatusFieldRow" */  '@components/PolicyClaimStatusFieldRow/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-claim-status-field-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyClaimStatusField.index, name: routes.policyClaimStatusField.index,
            component: () => import(/* webpackChunkName: "PolicyClaimStatusField" */  '@components/PolicyClaimStatusField/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-claim-status-row-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyClaimStatusRow.index, name: routes.policyClaimStatusRow.index,
            component: () => import(/* webpackChunkName: "PolicyClaimStatusRow" */  '@components/PolicyClaimStatusRow/Index')
        }]);
    }
    if (RoutesbyPermsc['policy-city-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.policyCity.index, name: routes.policyCity.index,
            component: () => import(/* webpackChunkName: "PolicyCity" */  '@components/PolicyCity/Index')
        }]);
    }
    if (RoutesbyPermsc['contract-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.contractType.index, name: routes.contractType.index,
            component: () => import(/* webpackChunkName: "ContractType" */  '@components/ContractType/Index')
        }]);
    }
    if (RoutesbyPermsc['medical-discharge-condition-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalDischargeCondition.index, name: routes.medicalDischargeCondition.index,
            component: () => import(/* webpackChunkName: "MedicalDischargeCondition" */  '@components/MedicalDischargeCondition/Index')
        }]);
    }
    if (RoutesbyPermsc['medical-accident-destination-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalAccidentDestination.index, name: routes.medicalAccidentDestination.index,
            component: () => import(/* webpackChunkName: "MedicalAccidentDestination" */  '@components/MedicalAccidentDestination/Index')
        }]);
    }
    if (RoutesbyPermsc['medical-condition-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.medicalCondition.index, name: routes.medicalCondition.index,
            component: () => import(/* webpackChunkName: "MedicalCondition" */  '@components/MedicalCondition/Index')
        }]);
    }
    if (RoutesbyPermsc['bill-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.bill.index, name: routes.bill.index,
            component: () => import(/* webpackChunkName: "Bill" */  '@components/Bill/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-action-row-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalActionRow.index, name: routes.legalActionRow.index,
            component: () => import(/* webpackChunkName: "LegalActionRow" */  '@components/LegalActionRow/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-action-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalAction.index, name: routes.legalAction.index,
            component: () => import(/* webpackChunkName: "LegalAction" */  '@components/LegalAction/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-case-category-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalCaseCategory.index, name: routes.legalCaseCategory.index,
            component: () => import(/* webpackChunkName: "LegalCaseCategory" */  '@components/LegalCaseCategory/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-contract-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalContractType.index, name: routes.legalContractType.index,
            component: () => import(/* webpackChunkName: "LegalContractType" */  '@components/LegalContractType/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-action-field-row-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalActionFieldRow.index, name: routes.legalActionFieldRow.index,
            component: () => import(/* webpackChunkName: "LegalActionFieldRow" */  '@components/LegalActionFieldRow/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-action-request-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalActionRequest.index, name: routes.legalActionRequest.index,
            component: () => import(/* webpackChunkName: "LegalActionRequest" */  '@components/LegalActionRequest/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-action-request-update-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalActionRequestUpdate.index, name: routes.legalActionRequestUpdate.index,
            component: () => import(/* webpackChunkName: "LegalActionRequestUpdate" */  '@components/LegalActionRequestUpdate/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-assigned-legal-case-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalAssignedLegalCase.index, name: routes.legalAssignedLegalCase.index,
            component: () => import(/* webpackChunkName: "LegalAssignedLegalCase" */  '@components/LegalAssignedLegalCase/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-case-session-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalCaseSession.index, name: routes.legalCaseSession.index,
            component: () => import(/* webpackChunkName: "LegalCaseSession" */  '@components/LegalCaseSession/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-request-type-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalRequestType.index, name: routes.legalRequestType.index,
            component: () => import(/* webpackChunkName: "LegalRequestType" */  '@components/LegalRequestType/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-action-field-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalActionField.index, name: routes.legalActionField.index,
            component: () => import(/* webpackChunkName: "LegalActionField" */  '@components/LegalActionField/Index')
        }]);
    }
    if (RoutesbyPermsc['legal-request-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.legalRequest.index, name: routes.legalRequest.index,
            component: () => import(/* webpackChunkName: "LegalRequest" */  '@components/LegalRequest/Index')
        }]);
    }
    if (RoutesbyPermsc['user-index']) {
        routeAlliesbypermsc.push(...[{
            path: routes.user.index,name: routes.user.index,
            component: () => import(/* webpackChunkName: "User" */  '@components/user/Index')
        }]);
        routeAlliesbypermsc.push(...[{
                path: 'profile',name: routes.user.profile,
                component: () => import(/* webpackChunkName: "Profile" */ '@components/user/Profile')
        }]);
    }
    else{
        routeAlliesbypermsc.push(...[{
            path: 'profile',name: routes.user.profile,
            component: () => import(/* webpackChunkName: "Profile" */ '@components/user/Profile')
        }]);
    }
}
export default [
    {
        path: '',
        redirect: HOME_ROUTE_NAME,
        component: () => import( /* webpackChunkName: "App" */ '@views/App'),
        meta: {
            auth: true
        },
        children: [
            {
                path: routes.landing.home,
                name: routes.landing.home,
                component: () => import(/* webpackChunkName: "LandingHome" */ '@components/Home')
            },
            ...routeAlliesbypermsc,
        ]
    },
];
