export default {
  name: 'Name',
  name_hint: 'Name field required',
  name_ar: 'Arabic Name',
  name_ar_hint: 'Arabic Name field required',
  name_en: 'English Name',
  name_en_hint: 'English Name field required',
  login_id: 'UserName',
  login_id_hint: 'UserName field required',
  password: 'Password',
  password_hint: 'Password field required',
  password_confirmation: 'Password Confirmation',
  password_confirmation_hint: 'Password Confirmation field required',
  code: 'Code',
  code_hint: 'Code field required',
  age: 'Age',
  age_hint: 'Age field required',
  birth_date: 'Birth Date',
  birth_date_hint: 'Birth Date field required',
  identity_id: 'Identity Number',
  identity_id_hint: 'Identity Number field required',
  identity: 'Identity Number',
  identity_hint: 'Identity Number field required',
  insurance_company_id: 'Insurance Company',
  insurance_company_id_hint: 'Insurance Company field required',
  insurance_policy_id: 'Policy Id',
  insurance_policy_id_hint: 'Policy Id field required',
  insurance_membership_id: 'Membership Id',
  insurance_membership_id_hint: 'Membership Id field required',
  mobile: 'Mobile',
  mobile_hint: 'Mobile field required',
  sort_order: 'Sort order',
  active: 'Enabled',
  percent: 'Percentage',
  status: 'Status',
  status_label: 'Status',
  other: 'Other',
  false: 'Disabled',
  true: 'Enabled',
  accident_number: 'Accident Number',
  accident_number_hint: 'Accident Number field required',
  assign_type: 'Assign Type',
  assign_type_hint: 'Assign Type field required',
  consumer_id: 'Injured',
  consumer_id_hint: 'Injured field required',
  accident_date_no_type: 'Accident Date',
  accident_date: 'Accident Date',
  accident_date_hint: 'Accident Date field required',
  accident_date_hijri: 'Hijri Accident Date',
  accident_date_hijri_hint: 'Hijri Accident Date field required',
  accident_type_id: 'Accident Type',
  accident_type_id_hint: 'Accident Type field required',
  city_id: 'City',
  city_id_hint: 'City field required',
  division_id: 'Division',
  division_id_hint: 'Division field required',
  notes: 'Notes',
  notes_hint: 'Notes field required',
  claim_status1: 'Main Status',
  claim_status2: 'Sub Status',
  assign_types1: 'Private Assign',
  assign_types2: 'Public Assign',
  consumer_type0: 'Main',
  consumer_type1: 'Sub',
  gender1: 'Male',
  gender2: 'Female',
  medicalfiletype0: 'None',
  medicalfiletype1: 'Original',
  medicalfiletype2: 'Photo Copy',
  registrationtype1: 'Private',
  registrationtype2: 'Private Transport',
  registrationtype3: 'Public Transport',
  registrationtype4: 'Public Taxi',
  medicalaccidentfiletype0: 'System Generated',
  medicalaccidentfiletype1: 'Written',
  medicalaccidentfiletype2: 'Sketch',
  car_claim_status1: 'Main Procedure',
  car_claim_status2: 'Sub Procedure',
  menu_type1: 'Main Menu',
  menu_type2: 'Sub Menu',
  parent_id: 'Belongs To:',
  parent_id_hint: 'Belongs To field required',
  claim_status_id: 'Claim Status',
  claim_status_id_hint: 'Claim Status field required',
  claim_status_parent_id: 'Claim Parent Status',
  claim_status_parent_id_hint: 'Claim Parent Status field required',
  field_type_id: 'Field Type',
  field_type_id_hint: 'Field Type field required',
  claim_number: 'Claim Number',
  claim_number_hint: 'Claim Number field required',
  claim_date: 'Claim Date',
  claim_date_hint: 'Claim Date field required',
  invoice_number: 'Invoice Number',
  invoice_number_hint: 'Invoice Number field required',
  invoice_date: 'Invoice Date',
  invoice_date_hint: 'Invoice Date field required',
  hospital_id: 'Hospital',
  hospital_id_hint: 'Hospital field required',
  hospital_code: 'Hospital Code',
  hospital_code_hint: 'Hospital Code field required',
  hospital_address: 'Hospital Address',
  hospital_address_hint: 'Hospital Address field required',
  hospital_file_id: 'Hospital File',
  hospital_file_id_hint: 'Hospital File field required',
  accident_id: 'Accident Number',
  accident_id_hint: 'Accident Number field required',
  medical_condition_id: 'Medical Condition',
  medical_condition_id_hint: 'Medical Condition field required',
  year_id:'Claim Period',
  year_id_hint:'Claim Period field required',
  provider_code:'Provider Code',
  provider_code_hint:'Provider Code field required',
  original_amount:'Original Amount',
  original_amount_hint:'Original Amount field required',
  final_amount:'Final Amount',
  final_amount_hint:'Final Amount field required',
  work_for:'Work For',
  work_for_hint:'Work For field required',
  type:'Type',
  type_hint:'Type field required',
  digit_type:'Digit Type',
  digit_type_hint:'Digit Type field required',
  digit:'Digit',
  digit_hint:'Digit field required',
  digit_type1: 'Letter',
  digit_type2: 'Number',
  consumer_name: 'Injured Name',
  consumer_name_hint: 'Injured Name field required',
  client_id: 'Client',
  client_id_hint: 'Client field required',
  party: 'Party',
  party_number_hint: 'Party Number field required',
  vehicle_type_id: 'Vehicle Type',
  vehicle_type_id_hint: 'Vehicle Type field required',
  vehicle_model: 'Vehicle Model',
  vehicle_model_id: 'Vehicle Model',
  vehicle_model_id_hint: 'Vehicle Model field required',
  vehicle_serial_number: 'Vehicle Serial Number',
  vehicle_serial_number_hint: 'Vehicle Serial Number field required',
  vehicle_chassie_number: 'Vehicle Serial Number',
  vehicle_chassie_number_hint: 'Vehicle Chassie Number field required',
  insurance_policy: 'Insurance Policy',
  insurance_policy_hint: 'Insurance Policy field required',
  insurance_expiration_date: 'Insurance  Expiration Date',
  insurance_expiration_date_hint: 'Insurance Expiration Date field required',
  insurance_expiration_date_hijri: 'Insurance  Expiration Date Hij',
  insurance_expiration_date_hint_hijri: 'Insurance Expiration Date Hij field required',
  tolerance_ratio: 'Tolerance Ratio',
  tolerance_ratio_hint: 'Tolerance Ratio field required',
  phone: 'Phone',
  phone_hint: 'Phone field required',
  email: 'Email',
  email_hint: 'Email field required',
  contact_name: 'Contact Name',
  contact_name_hint: 'Contact Name field required',
  contact_mobile: 'Contact Mobile',
  contact_mobile_hint: 'Contact Mobile field required',
  contact_fax: 'Contact Fax',
  contact_fax_hint: 'Contact Fax field required',
  contact_phone: 'Contact Phone',
  contact_phone_hint: 'Contact Phone field required',
  contact_email: 'Email',
  contact_email_hint: 'Email field required',
  consumer_work_id: 'Injured Work for',
  consumer_work_id_hint: 'Injured Work for field required',
  plate_id_1: 'Letter1',
  plate_id_1_hint: 'Letter1 field required',
  plate_id_2: 'Letter2',
  plate_id_2_hint: 'Letter2 field required',
  plate_id_3: 'Letter3',
  plate_id_3_hint: 'Letter3 field required',
  plate_id_numbers: 'Numbers',
  plate_id_numbers_hint: 'Numbers field required',
  party_name: 'Party name',
  party_name_hint: 'Party name field required',
  party_identity: 'Party Identity',
  party_identity_hint: 'Party Identity field required',
  party_type_id: 'Party Type',
  party_type_id_hint: 'Party Type field required',
  party_person_type_id: 'Party Person Type',
  party_person_type_id_hint: 'Party Person Type field required',
  is_consumer: 'Does Injured in This Vehicle',
  is_consumer_driver: 'Does Injured The Driver',
  claim_type_id: 'Claim Type',
  claim_type_id_hint: 'Claim Type field required',
  status_date: 'Status Date',
  status_date_hint: 'Status Date field required',
  party_id: '؛Party',
  party_id_hint: 'Party field required',
  menu_url: 'Menu URL',
  menu_url_hint: 'Menu URL field required',
  perm: 'Permission',
  perm_hint: 'Permission field required',
  icon: 'Icon',
  icon_hint: 'Icon field required',
  system_type: 'Belongs to system:',
  system_type_hint: 'Belongs to system field required',
  model_name: 'Belongs to Module',
  model_name_hint: 'Belongs to Module field required',
  status_type: 'Status Type',
  status_type_hint: 'Status Type field required',
  policy_status_type: 'Procedure Type',
  policy_status_type_hint: 'Procedure Type field required',
  car_status_type: 'Procedure Type',
  car_status_type_hint: 'Procedure Type field required',
  medical_status_type: 'Procedure Type',
  medical_status_type_hint: 'Procedure Type field required',
  role_id: 'Role ID',
  permission_id: 'Permission ID',
  present_date: 'Present date',
  paid_date: 'Paid Date',
  paid_amount: 'Paid Amount',
  car_claim_type_id: 'Claim Type',
  car_claim_type_id_hint: 'Claim Type field required',
  fieldscount: 'Fields',
  policy_claim_status_id: 'Claim Procedure',
  policy_claim_status_id_hint: 'Claim Procedure field required',
  policy_claim_status_parent_id: 'Main Claim Procedure',
  policy_claim_status_parent_id_hint: 'Main Claim Procedure field required',
  car_claim_status_id: 'Claim Procedure',
  car_claim_status_id_hint: 'Claim Procedure field required',
  car_claim_status_parent_id: 'Main Claim Procedure',
  car_claim_status_parent_id_hint: 'Main Claim Procedure field required',
  target: 'Monthly Target',
  target_hint: 'Monthly Target field required',
  msgtext: 'Message Text',
  msgtext_hint: 'Message Text field required',
  sender_name: 'Sender Name',
  sender_name_hint: 'Sender Name field required',
  start_date: 'Contract Start Date',
  start_date_hint: 'Contract Start Date field required',
  terminte_date: 'Contract Terminte Date',
  terminte_date_hint: 'Contract Terminte Date field required',
  cusotmer_name_ar: 'Customer AR name',
  cusotmer_name_ar_hint: 'cusotmer AR field required',
  cusotmer_name_en: 'Customer EN name',
  cusotmer_name_en_hint: 'Customer EN field required',
  business_type_id: 'Business Type',
  business_type_id_hint: 'Business Type field required',
  police_client_id: 'Policy Client',
  police_client_id_hint: 'Policy Client field required',
  manager: 'Manager',
  manager_hint: 'Manager field required',
  iban: 'Iban',
  iban_hint: 'Iban field required',
  contact_person: 'Contact Person',
  contact_person_hint: 'Contact Person field required',
  regionC: 'Central',
  regionN: 'Northern',
  regionW: 'Western',
  regionE: 'Eastern',
  regionS: 'Southern',
}
