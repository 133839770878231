export default {
  Users: 'User|Users',
  InsuranceCompany: 'Insurance Company|Insurance Companies',
  Consumers: 'Injured|Injured People',
  AccidentTypes: 'Accident Type|Accident Types',
  Cities: 'City|Cities',
  ClaimTypes: 'Claim Type|Claim Types',
  VehicleTypes: 'Vehicle Type|Vehicle Types',
  Divisions: 'Division|Divisions',
  Hospitals: 'Hospital|Hospitals',
  Accidents: 'Accident|Accidents',
  ClaimStatuses: 'Claim Status|Claim Statuses',
  ClaimStatusFields: 'Claim Status Field|Claim Status Fields',
  Claims: 'Claim|Claims',
  PartyPersonTypes: 'PartyData Person Type|PartyData Person Types',
  PlateDigits: 'Car Plate Digit|Car Plate Digits',
  Clients: 'Client|Clients',
  Years: 'Year|Years',
  Parties: 'PartyData|Parties',
  PartyPerson: 'Party Persons|Party Persons',
  PartyPersons: 'Party Person|Party Persons',
  HospitalContacts: 'Hospital Contact|Hospital Contacts',
  ConsumerWorks: 'Consumer Work|Consumer Works',
  PartyStatuses: 'Party Status|Party Statuses',
  PartyStatusRows: 'Party Status Row|Party Status Rows',
  VehicleModels: 'Vehicle Model|Vehicle Models',
  PartyTypes: 'Party Type|Party Types',
  Menus: 'System Menu|System Menus',
  PermissionRoles: 'Permission Role|Permission Roles',
  Permissions: 'Permission|Permissions',
  Roles: 'Role|Roles',
  DocumentTypes: 'Document Type|Document Types',
  MedicalReportones: 'تقرير حالة المطالبات|تقرير حالة المطالبات',
  VehicalReporttwos: 'تقرير التحصيل|تقرير التحصيل',
  VehicalReportthrees: 'تقرير تحصيل العملاء|تقرير تحصيل العملاء',
  MedicalReporttwos: 'تقرير حالة المطالبات حسب تاريخ الاستلام|تقرير حالة المطالبات حسب تاريخ الاستلام',
  MedicalReportthrees: 'المطالبات المقدمة لشركات التأمین|المطالبات المقدمة لشركات التأمین',
  MedicalReportfours: 'تقرير المطالبات العام|تقرير المطالبات العام',
  MedicalReportfives: 'المطالبات المحصلة|المطالبات المحصلة',
  MedicalReportsixes: 'حالات المطالبات على مستوى مستخدم|حالات المطالبات على مستوى مستخدم',
  MedicalReportsevens: 'كشف التحصیل الشھري|كشف التحصیل الشھري',
  MedicalReporteights: 'Final Result Report|Final Result Report',
  LegalPartyTypes: 'LegalPartyType|LegalPartyTypes',
  LegalParties: 'LegalParty|LegalParties',
  LegalLitigationDegrees: 'LegalLitigationDegree|LegalLitigationDegrees',
  LegalClientTypes: 'LegalClientType|LegalClientTypes',
  LegalClients: 'LegalClient|LegalClients',
  LegalCaseTypes: 'LegalCaseType|LegalCaseTypes',
  LegalCaseStatuses: 'LegalCaseStatus|LegalCaseStatuses',
  LegalCases: 'LegalCase|LegalCases',
  CarCities: 'City|Cities',
  CarSmsTemplates: 'SMS Template|SMS Templates',
  BusinessTypes: 'Business Type|Business Types',
  PolicyClaims: 'Policy Claim|Policy Claims',
  PolicyClients: 'Policy Client|Policy Clients',PolicyClaimStatusRows: 'PolicyClaimStatusRow|PolicyClaimStatusRows',
  PolicyClaimStatusFieldRows: 'Policy Claim Status Field Row|Policy Claim Status Field Rows',
  PolicyClaimStatuses: 'Policy Claim Status|Policy Claim Statuses',
  PolicyClaimNotes: 'Policy Claim Note|Policy Claim Notes',
  PolicyAssignedClaims: 'Policy Assigned Claim|Policy Assigned Claims',
  PolicyClaimStatusFields: 'Policy Claim Status Field|Policy Claim Status Fields',
  PolicyCities: 'City|Cities',
  ContractTypes: 'Contract Type|Contract Types',
  MedicalConditions: 'Medical Condition|Medical Conditions',
  Bills: 'Bill|Bills',
  MedicalClaims: 'Claim|Claims',
  LegalRequests: 'Legal Request|Legal Requests',
  MedicalClaimStatuses: 'Final Result Status|Final Result Statuses',
  MedicalClaimStatusFields: 'Final Result Status Field|Final Result Status Fields',
};
