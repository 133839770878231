



<template>
  <div>
<!--    <v-footer padless elevation="3">-->
<!--      <app-row justify="center" no-gutters>-->
<!--        <v-col class="gray py-3 text-center" cols="12">-->
<!--          {{ new Date().getFullYear() }} — <strong>MyTh</strong>-->
<!--        </app-col>-->
<!--      </app-row>-->
<!--    </v-footer>-->
  </div>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style scoped>

</style>
