/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      identity_id: 'identity_id',
    consumer_id: 'consumer_id',
    insurance_company_id: 'insurance_company_id',
    insurance_policy: 'insurance_policy',
    insurance_membership: 'insurance_membership',
    status: 'status',

};
