/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

import axios from 'axios';
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;
export default {
    discounts(params = {}, config = {}) {
    const url = Resource('InsuranceDocuments');
    return params === true ? url : axios.get(url, {...config, params: {...params, itemsPerPage: -1}});
  }
};
