/* SarhERP Project Copyright © 2021 SarhSoft All rights reserved M.Farhat */

export default {
      medical_claim_id: 'medical_claim_id',
    medical_claim_status_id: 'medical_claim_status_id',
    medical_claim_status_row_id: 'medical_claim_status_row_id',
    medical_claim_status_field_id: 'medical_claim_status_field_id',
    field_value: 'field_value',

};
